html,
body {
  height: 100%;
}

#root {
  height: 100%;
}

.body {
  background-color: #fbf6ef;
  height: 100%;
}

h1,h2,h3,h4,h5 {}

h1 {
  margin: 0px;
  margin-bottom: 10px;
}

button {
  font-family: 'Montserrat';
  font-weight: bold;
}
